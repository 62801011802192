import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import React, { useState } from 'react';
import { KTSVG } from '../../_metronic/helpers';

type Props = {
    customThankYouText: string | null;
    rating: number;
    ratingInterpretation: string | null;
    isPrivate: boolean;
    isFormSubmitted: boolean;
    onFormSubmit: (shareResult: boolean, wantTraining: boolean, wantEmployment: boolean) => void;
};

const Completed: React.FC<Props> = ({
    customThankYouText,
    rating,
    ratingInterpretation,
    isPrivate,
    isFormSubmitted,
    onFormSubmit
}) => {

    const [shareResult, setShareResult] = useState(false);
    const [wantTraining, setWantTraining] = useState(false);
    const [wantEmployment, setWantEmployment] = useState(false);

    const perfectScore = 5;

    let thankYouText = 'You have successfully completed the assessment.';

    if (isPrivate) {
        thankYouText += ' Please close this window to exit the system. Thank you!';
    }

    if (customThankYouText && customThankYouText.length > 0) {
        thankYouText = customThankYouText;
    }

    const handleSubmitButtonClick = () => {
        onFormSubmit(shareResult, wantTraining, wantEmployment);
    };

    return (
        <div className='mt-10 bg-white p-8 border rounded'>
            <div className='mt-10 text-center'>
                <h1>Assessment Completed</h1>
            </div>
            <div className='mx-auto mw-600px w-100 pt-10 pb-10'>
                <div className='fs-4'>
                    <div>
                        {thankYouText}
                    </div>
                    {!isPrivate && (
                        <div>
                            <div className='pt-10 fs-5'>
                                {[...Array(rating)].map(i => (
                                    <FontAwesomeIcon icon={faStar} className='text-warning' size='2x'></FontAwesomeIcon>
                                ))}
                                {[...Array(perfectScore - rating)].map(i => (
                                    <FontAwesomeIcon icon={faStar} className='text-secondary' size='2x'></FontAwesomeIcon>
                                ))}
                            </div>
                            {ratingInterpretation != null && (
                                <div className='pt-10 fs-5' dangerouslySetInnerHTML={{ __html: ratingInterpretation }}>
                                </div>
                            )}
                            {!isFormSubmitted && (
                                <div className='pt-10 fs-5'>
                                    <h3>Share your result with our partners:</h3>
                                    <p>
                                        Our partners are interested in discussing training and employment opportunities
                                        with candidates who are a strong behavioral match for the jobs they offer.
                                    </p>
                                    <p>
                                        Your result is currently saved as "private", which means only you can see it. If
                                        you wish to share your result and contact information with JOBehaviors' partners,
                                        please check the option below:
                                    </p>

                                    <div className='d-flex flex-stack'>
                                        <label className='form-check form-switch form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input border border-gray-300'
                                                type='checkbox'
                                                checked={shareResult}
                                                onChange={() => setShareResult(!shareResult)}
                                            />
                                            <span className='form-check-label fw-bold'>
                                                Share my results with JOBehaviors partners.
                                            </span>
                                        </label>
                                    </div>

                                    <div className='pt-7'>
                                        <p>
                                            Please indicate your interest in the following for this particular assessment:
                                        </p>

                                        <div className='d-flex flex-stack'>
                                            <label className='form-check form-switch form-check-custom form-check-solid'>
                                                <input
                                                    className='form-check-input border border-gray-300'
                                                    type='checkbox'
                                                    checked={wantTraining}
                                                    onChange={() => setWantTraining(!wantTraining)}
                                                />
                                                <span className='form-check-label fw-bold'>
                                                    Job Training
                                                </span>
                                            </label>
                                        </div>

                                        <div className='d-flex flex-stack pt-1'>
                                            <label className='form-check form-switch form-check-custom form-check-solid'>
                                                <input
                                                    className='form-check-input border border-gray-300'
                                                    type='checkbox'
                                                    checked={wantEmployment}
                                                    onChange={() => setWantEmployment(!wantEmployment)}
                                                />
                                                <span className='form-check-label fw-bold'>
                                                    Employment
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column pt-10 flex-center'>
                                        <button type='submit' className='btn btn-lg btn-primary me-3' onClick={handleSubmitButtonClick}>
                                            <span className='indicator-label'>
                                                Submit
                                                <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
                                            </span>
                                        </button>
                                    </div>

                                </div>
                            )}
                            {isFormSubmitted && (
                                <div className='pt-10 fs-5'>
                                    {shareResult && (
                                        <span>
                                            Your results and contact information will be shared with JOBehaviors partners.&nbsp;
                                        </span>
                                    )}
                                    Please close this window to exit the system. Thank you!
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Completed;
