import React from 'react';

type Props = {
    currentQuestionNumber: number;
    questionCount: number;
};

const ProgressBar: React.FC<Props> = ({ currentQuestionNumber, questionCount }) => {
    const progress = (currentQuestionNumber / questionCount) * 100;

    return (
        <div className='d-flex flex-column w-100 me-2 mb-10'>
            <div className='d-flex flex-stack mb-2'>
                <div className='text-muted me-2 fs-6 fw-bold'>
                    Question {currentQuestionNumber} of {questionCount}
                </div>
            </div>
            <div className='progress h-10px w-100'>
                <div
                    className='progress-bar bg-warning'
                    role={'progressbar'}
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin={0}
                    aria-valuemax={100}
                ></div>
            </div>
        </div>
    );
};

export default ProgressBar;
