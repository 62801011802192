import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle }  from '@fortawesome/free-solid-svg-icons'



type Props = {
    show: boolean;
    onTryAgain?: () => void;
};

const ErrorAlert: React.FC<Props> = ({ show, onTryAgain }) => {
    if (show) {
        return (        
            <div className="card card-stretch mb-5">
                <div className="card-body p-20">
                    <div className='text-center pb-10'>
                        <FontAwesomeIcon icon={faExclamationTriangle} className='text-danger' size='5x'></FontAwesomeIcon>
                    </div>
                    <h1 className='text-center p-6'>An error was encountered.</h1>
                    {onTryAgain && (
                        <div className='text-center fs-4'>
                            <a href="#" className='btn btn-light-primary' onClick={onTryAgain}>Please click here to try again.</a>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    return null;
};

export default ErrorAlert;
