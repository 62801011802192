import React from 'react';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    showPreviousButton: boolean;
    showNextButton: boolean;
    onPrevious: () => void;
    onNext: () => void;
};

const NavigationButtons: React.FC<Props> = ({ showPreviousButton, showNextButton, onPrevious, onNext }) => {
    return (
        <>
                <button type='button' 
                    className={`btn btn-navigation btn-light-primary me-3 px-4 py-2 bg-opacity-0${!showPreviousButton ? ' text-muted' : ''}`} 
                    disabled={!showPreviousButton} 
                    onClick={onPrevious}
                >
                    <FontAwesomeIcon icon={faAngleLeft} size='lg'></FontAwesomeIcon>
                </button>
                <button type='button' 
                    className={`btn btn-navigation btn-light-primary px-4 py-2 bg-opacity-0${!showNextButton ? ' text-muted' : ''}`} 
                    disabled={!showNextButton} 
                    onClick={onNext}
                >
                    <FontAwesomeIcon icon={faAngleRight} size='lg'></FontAwesomeIcon>
                </button>
        </>
    );
};

export default NavigationButtons;
