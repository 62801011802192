import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AssessmentModel } from '../../models/AssessmentModel';
import { CandidateProfileModel } from '../../models/CandidateProfileModel';
import Registration from '../../components/Registration/Registration';
import CustomQuestionnaire from '../../components/Questionnaire/CustomQuestionnaire/CustomQuestionnaire';
import PairQuestionnaire from '../../components/Questionnaire/PairQuestionnaire/PairQuestionnaire';
import { CustomAnswerModel } from '../../models/CustomAnswerModel';
import { CandidateAnswerModel } from '../../models/CandidateAnswerModel';
import Completed from '../../components/Completed/Completed';
import { KeyValueModel } from '../../models/KeyValueModel';
import { toAbsoluteUrl } from '../../_metronic/helpers';
import Spinner from '../../components/Spinner/Spinner';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import { AssessmentResultModel } from '../../models/AssessmentResultModel';

const AssessmentPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [step, setStep] = useState('registration');
    const [assessmentData, setAssessmentData] = useState(null as AssessmentModel | null);
    const [sessionAssessmentId, setSessionAssessmentId] = useState(
        (sessionStorage.getItem('assessmentId') ? JSON.parse(sessionStorage.getItem('assessmentId') || '') : '') as string
    );
    const [sessionLocAssessmentId, setSessionLocAssessmentId] = useState(
        (sessionStorage.getItem('locAssessmentId') ? JSON.parse(sessionStorage.getItem('locAssessmentId') || '') : '') as string
    );
    const [registrationId, setRegistrationId] = useState(
        (sessionStorage.getItem('registrationId')
            ? JSON.parse(sessionStorage.getItem('registrationId') || '')
            : null) as number | null
    );
    const [candidateProfile, setCandidateProfile] = useState(
        (sessionStorage.getItem('candidateProfile')
            ? JSON.parse(sessionStorage.getItem('candidateProfile') || '')
            : null) as CandidateProfileModel | null
    );
    const [preAssessmentAnswers, setPreAssessmentAnswers] = useState(
        (sessionStorage.getItem('preAssessmentAnswers')
            ? JSON.parse(sessionStorage.getItem('preAssessmentAnswers') || '')
            : []) as CustomAnswerModel[]
    );
    const [assessmentAnswers, setAssessmentAnswers] = useState(
        (sessionStorage.getItem('assessmentAnswers')
            ? JSON.parse(sessionStorage.getItem('assessmentAnswers') || '')
            : []) as CandidateAnswerModel[]
    );
    const [isAssessmentComplete, setIsAssessmentComplete] = useState(false);
    const [resultId, setResultId] = useState(0);
    const [customThankYouText, setCustomThankYouText] = useState(null as string | null);
    const [rating, setRating] = useState(0);
    const [ratingInterpretation, setRatingInterpretation] = useState(null as string | null);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    //todo: move
    const partnerLogoBaseUrl = 'https://jobehaviors.com/Partner/PartnerFiles/Logos/';

    let { assessmentId, locAssessmentId } = useParams<{ assessmentId: string; locAssessmentId: string }>();

    useEffect(() => {
        if (!locAssessmentId) {
            locAssessmentId = '';
        }
        if (sessionAssessmentId !== assessmentId || sessionLocAssessmentId !== locAssessmentId) {
            setSessionAssessmentId(assessmentId);
            setSessionLocAssessmentId(locAssessmentId);
            restartAssessment();
        }
        (async (): Promise<void> => {
            try {
                setIsLoading(true);
                setHasError(false);

                const response = await fetch(`/api/assessment/${assessmentId}?la=${locAssessmentId}`);
                if (response.ok) {
                    const data: AssessmentModel = await response.json();
                    setAssessmentData(data);
                }
                else {
                    setHasError(true);
                }
            }
            catch (error) {
                setHasError(true);
            }
            finally {
                setIsLoading(false);
            }
        })();
    }, [assessmentId, locAssessmentId]);

    useEffect(() => {
        if (assessmentData?.CustomClientScript && assessmentData.CustomClientScript !== '') {
            const script = document.createElement('script');
            script.src = `/scripts/${assessmentData.CustomClientScript}.js`;
            script.async = true;
            document.body.appendChild(script);
        }
    }, [assessmentData]);

    useEffect(() => {
        sessionStorage.setItem('assessmentId', JSON.stringify(sessionAssessmentId));
    }, [sessionAssessmentId]);

    useEffect(() => {
        sessionStorage.setItem('locAssessmentId', JSON.stringify(sessionLocAssessmentId));
    }, [sessionLocAssessmentId]);

    useEffect(() => {
        sessionStorage.setItem('registrationId', JSON.stringify(registrationId));
    }, [registrationId]);

    useEffect(() => {
        sessionStorage.setItem('candidateProfile', JSON.stringify(candidateProfile));
    }, [candidateProfile]);

    useEffect(() => {
        sessionStorage.setItem('preAssessmentAnswers', JSON.stringify(preAssessmentAnswers));
    }, [preAssessmentAnswers]);

    useEffect(() => {
        sessionStorage.setItem('assessmentAnswers', JSON.stringify(assessmentAnswers));
    }, [assessmentAnswers]);

    useEffect(() => {
        const getAssessmentStep = () => {
            if (assessmentData &&
                !candidateProfile &&
                !isAssessmentComplete) {
                return 'registration';
            }
            else if (assessmentData &&
                candidateProfile &&
                preAssessmentAnswers.length < assessmentData.CustomQuestions.length &&
                !isAssessmentComplete) {
                return 'preassessment';
            }
            else if (assessmentData &&
                candidateProfile &&
                preAssessmentAnswers.length === assessmentData.CustomQuestions.length &&
                assessmentAnswers.length < assessmentData.Questions.length &&
                !isAssessmentComplete) {
                return 'assessment';
            }
            else if (assessmentData &&
                isAssessmentComplete &&
                (!assessmentData.CustomThankYouPage || assessmentData.CustomThankYouPage.trim() === '')) {
                return 'completed';
            }
            else if (assessmentData &&
                isAssessmentComplete &&
                (assessmentData.CustomThankYouPage && assessmentData.CustomThankYouPage.trim().length > 0)) {
                setIsLoading(true);
                return 'redirecting';
            }
            return 'undefined';
        };
        const currentStep = getAssessmentStep();
        setHasError(currentStep === 'undefined');
        setStep(currentStep);
    }, [
        assessmentData,
        candidateProfile,
        isAssessmentComplete,
        preAssessmentAnswers,
        assessmentAnswers
    ]);

    const restartAssessment = () => {
        setRegistrationId(0);
        setCandidateProfile(null);
        setPreAssessmentAnswers([]);
        setAssessmentAnswers([]);
    };

    const handleErrorTryAgain = () => {
        restartAssessment();
    };

    const handleRegistrationFormSubmit = (registrationFormValues: CandidateProfileModel) => {
        setCandidateProfile(registrationFormValues);

        if (assessmentData) {
            const registrationData = {
                assessmentTypeID: assessmentData.AssessmentTypeID,
                locAssessmentID: assessmentData.LocAssessmentID,
                candidateProfile: registrationFormValues
            };

            (async (): Promise<void> => {
                try {
                    const response = await fetch('/api/candidate', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(registrationData),
                    });
                    if (response.ok) {
                        const candidateId: number = await response.json();

                        setRegistrationId(candidateId);
                    }
                } catch (error) {
                    console.log('error', error);
                }
            })();
        }
    };

    const handlePreAssessmentFormSubmit = (preAssessmentFormValues: CustomAnswerModel[]) => {
        setPreAssessmentAnswers(preAssessmentFormValues);
    };

    const handleAssessmentFormSubmit = (assessmentFormValues: CandidateAnswerModel[]) => {
        setAssessmentAnswers(assessmentFormValues);

        const customParams = assessmentData?.CustomRegistrationInputs?.map(element => {
            return {
                Key: element.Key,
                Value: (candidateProfile as any)[element.Key]
            } as KeyValueModel;
        });

        if (assessmentData) {
            const completedAssessment = {
                partnerId: assessmentData.PartnerID,
                assessmentTypeID: assessmentData.AssessmentTypeID,
                locAssessmentID: assessmentData.LocAssessmentID,
                incompleteCandidateID: registrationId,
                isPrivate: assessmentData.IsPrivate,
                assessmentName: assessmentData.AssessmentTypeName,
                candidateProfile,
                customAnswers: preAssessmentAnswers,
                answers: assessmentFormValues,
                customParams: customParams
            };

            (async (): Promise<void> => {
                try {
                    setIsLoading(true);
                    setHasError(false);

                    const response = await fetch('/api/assessment', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(completedAssessment),
                    });
                    if (response.ok) {
                        const data: AssessmentResultModel = await response.json();

                        setIsAssessmentComplete(true);
                        setResultId(data.ResultId);
                        setCustomThankYouText(data.CustomThankYouText);
                        setRating(data.Rating);
                        setRatingInterpretation(data.RatingInterpretation);

                        restartAssessment();

                        if (assessmentData.CustomThankYouPage && assessmentData.CustomThankYouPage.trim().length > 0) {
                            window.location.href = '/completed/' + assessmentData.CustomThankYouPage;
                        }
                        else if (data.RedirectUrl && data.RedirectUrl.trim().length > 0) {
                            window.location.href = data.RedirectUrl;
                        }
                    }
                    else {
                        setHasError(true);
                    }
                } catch (error) {
                    setHasError(true);
                }
                finally {
                    setIsLoading(false);
                }
            })();
        }
    };

    const handleShareFormSubmit = (shareResult: boolean, wantTraining: boolean, wantEmployment: boolean) => {
        const shareResults = {
            ResultId: resultId,
            ShareResult: shareResult,
            WantTraining: wantTraining,
            WantEmployment: wantEmployment
        };

        (async (): Promise<void> => {
            try {
                setIsLoading(true);
                setHasError(false);

                const response = await fetch('/api/assessment/share', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(shareResults),
                });
                if (response.ok) {
                    setIsFormSubmitted(true);
                }
                else {
                    setHasError(true);
                }
            } catch (error) {
                setHasError(true);
            }
            finally {
                setIsLoading(false);
            }
        })();
    };

    const backgroundImage = assessmentData?.ImageHeader
        ? toAbsoluteUrl(`/media/headers/${assessmentData.ImageHeader}`)
        : toAbsoluteUrl('/media/headers/background.png');

    return (
        <>
            <Spinner show={isLoading}></Spinner>
            <ErrorAlert show={hasError} onTryAgain={handleErrorTryAgain}></ErrorAlert>
            {!hasError && assessmentData && (
                <div className='card'>
                    <div className='card-body bg-header'
                        style={{
                            backgroundImage: `url('${backgroundImage}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'top right',
                            backgroundPositionY: '40px'
                        }}
                    >
                        <div className='mx-auto mw-700px w-100 pt-10 pb-10'>
                            <div style={{ maxWidth: '400px' }}>
                                <img
                                    alt='Logo'
                                    className='logo-default'
                                    src={assessmentData?.LogoPath
                                        ? `${partnerLogoBaseUrl}/${assessmentData?.LogoPath}`
                                        : '/media/logos/logo.png'} />
                                <h1 className='pt-10 d-flex text-dark fw-bolder my-1 fs-1'><span>{assessmentData?.AssessmentTypeName}</span></h1>
                            </div>
                            {step === 'registration' && (
                                <Registration
                                    customIntroductionText={assessmentData.RegistrationIntroText}
                                    beforeAgreementText={assessmentData.BeforeAgreementText}
                                    hideAddress={assessmentData.HideAddress ?? false}
                                    customRegistrationInputs={assessmentData.CustomRegistrationInputs}
                                    onFormSubmit={handleRegistrationFormSubmit} />
                            )}
                            {step === 'preassessment' && (
                                <CustomQuestionnaire
                                    customQuestions={assessmentData.CustomQuestions}
                                    startQuestionNumber={preAssessmentAnswers.length + 1}
                                    onFormSubmit={handlePreAssessmentFormSubmit}
                                />
                            )}
                            {step === 'assessment' && (
                                <PairQuestionnaire
                                    questions={assessmentData.Questions}
                                    startQuestionNumber={assessmentAnswers.length + 1}
                                    onFormSubmit={handleAssessmentFormSubmit}
                                />
                            )}
                            {step === 'completed' && (
                                <Completed
                                    rating={rating}
                                    customThankYouText={customThankYouText}
                                    ratingInterpretation={ratingInterpretation}
                                    isPrivate={assessmentData.IsPrivate}
                                    isFormSubmitted={isFormSubmitted}
                                    onFormSubmit={handleShareFormSubmit}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AssessmentPage;
