import React from 'react';
import { KTSVG } from '../../../_metronic/helpers';

type Props = {
    questionId: number;
    optionId: number;
    label: string;
    isSelected: boolean;
    onSelect: (optionId: number) => void;
};

const ItemOption: React.FC<Props> = ({ questionId, optionId, label, isSelected, onSelect }) => {
    const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSelect(+event.target.value);
    };

    return (
        <>
            <label
                className={`btn btn-outline btn-outline-dashed btn-outline-default bg-white d-flex text-start p-6 mb-4${
                    isSelected ? ' active' : ''
                }`}
                data-kt-button='true'
                htmlFor={`option-${optionId.toString()}`}
            >
                <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                    <input
                        type='radio'
                        className='form-check-input border border-gray-300'
                        name={`question-${questionId.toString()}`}
                        value={optionId}
                        id={`option-${optionId.toString()}`}
                        checked={isSelected}
                        onChange={onValueChanged}
                    />
                </span>
                <span className='ms-5'>
                    <span className='d-block fw-bold text-start'>
                        <span className='text-dark d-block fs-5'>{label}</span>
                    </span>
                </span>
            </label>
        </>
    );
};

export default ItemOption;
