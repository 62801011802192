import { useEffect, useRef } from 'react';
import {
    MenuComponent,
    DrawerComponent,
    ScrollComponent,
    ScrollTopComponent,
    StickyComponent,
    ToggleComponent,
} from '../assets/ts/components';

import { useLayout } from './core';

export function MasterInit() {
    const { config } = useLayout();
    const isFirstRun = useRef(true);
    const pluginsInitialization = () => {
        isFirstRun.current = false;
        setTimeout(() => {
            ToggleComponent.bootstrap();
            ScrollTopComponent.bootstrap();
            DrawerComponent.bootstrap();
            StickyComponent.bootstrap();
            MenuComponent.bootstrap();
            ScrollComponent.bootstrap();
        }, 200);
    };

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            pluginsInitialization();
        }
    }, [config]);

    return <></>;
}
