import React from 'react';
import { KTSVG } from '../../../_metronic/helpers';

type Props = {
    show: boolean;
    onSubmit: () => void;
};

const SubmitButton: React.FC<Props> = ({ show, onSubmit }) => {
    if (show) {
        return (
            <div className='d-flex justify-content-center pt-5'>
                    <button type='submit' 
                        className='btn btn-lg btn-primary me-3'
                        onClick={onSubmit}
                    >
                        <span className='indicator-label'>
                            Submit answers
                            <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
                        </span>
                    </button>
            </div>
        );
    }
    return null;
};

export default SubmitButton;
