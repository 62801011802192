import React from 'react';

type Props = {
    show: boolean;
};

const Spinner: React.FC<Props> = ({ show }) => {
    if (show) {
        return (
            <div id='loading' className='page-overlay'>
                <div className="page-overlay-content">
                    <div className="d-flex align-items-center">
                        <span className="spinner-border text-warning" style={{width: '3rem', height: '3rem'}} role='status'></span>
                        <strong className='fs-4 ps-3 text-muted'>Loading...</strong>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

export default Spinner;
