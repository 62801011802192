import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nProvider } from './_metronic/i18n/i18nProvider';
import { LayoutSplashScreen, LayoutProvider } from './_metronic/layout/core';
import { MasterLayout } from './_metronic/layout/MasterLayout';
import Routes from './Routes';
import './App.css';

type Props = {
    basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={basename}>
                <I18nProvider>
                    <LayoutProvider>
                        <MasterLayout>
                            <Routes />
                        </MasterLayout>
                    </LayoutProvider>
                </I18nProvider>
            </BrowserRouter>
        </Suspense>
    );
};

export default App;
