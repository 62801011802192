import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { CandidateProfileModel } from '../../models/CandidateProfileModel';
import { KTSVG } from '../../_metronic/helpers';
import * as Yup from 'yup';
import { CustomInputModel } from '../../models/CustomInputModel';
import DropDownList from '../FormInput/DropDownList/DropDownList';
import TextBox from '../FormInput/TextBox/TextBox';
import RadioButtonList from '../FormInput/RadioButtonList/RadioButtonList';
import Checkbox from '../FormInput/Checkbox/Checkbox';
import { StateModel } from '../../models/StateModel';

type Props = {
    customIntroductionText: string | null;
    beforeAgreementText: string | null;
    hideAddress: boolean;
    customRegistrationInputs: CustomInputModel[];
    onFormSubmit: (values: CandidateProfileModel) => void;
};

const Registration: React.FC<Props> = ({ customIntroductionText, beforeAgreementText, hideAddress, customRegistrationInputs, onFormSubmit }) => {
    const formInitialValues: any = {
        Email: '',
        FirstName: '',
        LastName: '',
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        ZipCode: '',
        Phone: '',
        Country: 'US',
        AgreeToTerms: false,
    };

    const formValidationSchema: any = {
        Email: Yup.string().email('Invalid email address').required('Required'),
        FirstName: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
        LastName: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
        AgreeToTerms: Yup.boolean().required('Required').oneOf([true], 'You must accept the terms and conditions.'),
    };

    const states: StateModel[] = [
        { Country: 'US', StateCode: 'AL', StateName: 'Alabama' },
        { Country: 'US', StateCode: 'AK', StateName: 'Alaska' },
        { Country: 'US', StateCode: 'AZ', StateName: 'Arizona' },
        { Country: 'US', StateCode: 'AR', StateName: 'Arkansas' },
        { Country: 'US', StateCode: 'CA', StateName: 'California' },
        { Country: 'US', StateCode: 'CO', StateName: 'Colorado' },
        { Country: 'US', StateCode: 'CT', StateName: 'Connecticut' },
        { Country: 'US', StateCode: 'DE', StateName: 'Delaware' },
        { Country: 'US', StateCode: 'DC', StateName: 'Dist of Columbia' },
        { Country: 'US', StateCode: 'FL', StateName: 'Florida' },
        { Country: 'US', StateCode: 'GA', StateName: 'Georgia' },
        { Country: 'US', StateCode: 'HI', StateName: 'Hawaii' },
        { Country: 'US', StateCode: 'IL', StateName: 'Illinois' },
        { Country: 'US', StateCode: 'IN', StateName: 'Indiana' },
        { Country: 'US', StateCode: 'IA', StateName: 'Iowa' },
        { Country: 'US', StateCode: 'KS', StateName: 'Kansas' },
        { Country: 'US', StateCode: 'KY', StateName: 'Kentucky' },
        { Country: 'US', StateCode: 'LA', StateName: 'Louisiana' },
        { Country: 'US', StateCode: 'ME', StateName: 'Maine' },
        { Country: 'US', StateCode: 'MD', StateName: 'Maryland' },
        { Country: 'US', StateCode: 'MA', StateName: 'Massachusetts' },
        { Country: 'US', StateCode: 'MI', StateName: 'Michigan' },
        { Country: 'US', StateCode: 'MN', StateName: 'Minnesota' },
        { Country: 'US', StateCode: 'MS', StateName: 'Mississippi' },
        { Country: 'US', StateCode: 'MO', StateName: 'Missouri' },
        { Country: 'US', StateCode: 'MT', StateName: 'Montana' },
        { Country: 'US', StateCode: 'NE', StateName: 'Nebraska' },
        { Country: 'US', StateCode: 'NV', StateName: 'Nevada' },
        { Country: 'US', StateCode: 'NH', StateName: 'New Hampshire' },
        { Country: 'US', StateCode: 'NJ', StateName: 'New Jersey' },
        { Country: 'US', StateCode: 'NM', StateName: 'New Mexico' },
        { Country: 'US', StateCode: 'NY', StateName: 'New York' },
        { Country: 'US', StateCode: 'NC', StateName: 'North Carolina' },
        { Country: 'US', StateCode: 'ND', StateName: 'North Dakota' },
        { Country: 'US', StateCode: 'OH', StateName: 'Ohio' },
        { Country: 'US', StateCode: 'OK', StateName: 'Oklahoma' },
        { Country: 'US', StateCode: 'OR', StateName: 'Oregon' },
        { Country: 'US', StateCode: 'PA', StateName: 'Pennsylvania' },
        { Country: 'US', StateCode: 'RI', StateName: 'Rhode Island' },
        { Country: 'US', StateCode: 'SC', StateName: 'South Carolina' },
        { Country: 'US', StateCode: 'SD', StateName: 'South Dakota' },
        { Country: 'US', StateCode: 'TN', StateName: 'Tennessee' },
        { Country: 'US', StateCode: 'TX', StateName: 'Texas' },
        { Country: 'US', StateCode: 'UT', StateName: 'Utah' },
        { Country: 'US', StateCode: 'VT', StateName: 'Vermont' },
        { Country: 'US', StateCode: 'VA', StateName: 'Virginia' },
        { Country: 'US', StateCode: 'WA', StateName: 'Washington' },
        { Country: 'US', StateCode: 'WV', StateName: 'West Virginia' },
        { Country: 'US', StateCode: 'WI', StateName: 'Wisconsin' },
        { Country: 'US', StateCode: 'WY', StateName: 'Wyoming' },
        { Country: 'CA', StateCode: 'AB', StateName: 'Alberta' },
        { Country: 'CA', StateCode: 'BC', StateName: 'British Columbia' },
        { Country: 'CA', StateCode: 'MB', StateName: 'Manitoba' },
        { Country: 'CA', StateCode: 'NB', StateName: 'New Brunswick' },
        { Country: 'CA', StateCode: 'NF', StateName: 'New Foundland' },
        { Country: 'CA', StateCode: 'NT', StateName: 'Northwest Territories' },
        { Country: 'CA', StateCode: 'NS', StateName: 'Nova Scotia' },
        { Country: 'CA', StateCode: 'ON', StateName: 'Ontario' },
        { Country: 'CA', StateCode: 'PE', StateName: 'Prince Edward Island' },
        { Country: 'CA', StateCode: 'QC', StateName: 'Quebec' },
        { Country: 'CA', StateCode: 'SK', StateName: 'Saskatchewan' },
        { Country: 'CA', StateCode: 'YT', StateName: 'Yukon Territories' },
        { Country: 'AU', StateCode: 'AC', StateName: 'Australian Capital Territory' },
        { Country: 'AU', StateCode: 'SW', StateName: 'New South Wales' },
        { Country: 'AU', StateCode: 'NO', StateName: 'Northern Territory' },
        { Country: 'AU', StateCode: 'QL', StateName: 'Queensland' },
        { Country: 'AU', StateCode: 'SA', StateName: 'South Australia' },
        { Country: 'AU', StateCode: 'TS', StateName: 'Tasmania' },
        { Country: 'AU', StateCode: 'VI', StateName: 'Victoria' },
        { Country: 'AU', StateCode: 'WE', StateName: 'Western Australia' }
    ];

    const defaultCountryCode = 'US';
    const [stateItems, setStateItems] = useState(
        states.filter((item) => item.Country === defaultCountryCode)
    );

    if (!hideAddress) {
        formValidationSchema.Address1 = Yup.string().required('Required');
        formValidationSchema.City = Yup.string().required('Required');
        formValidationSchema.State = Yup.string().required('Required');
        formValidationSchema.ZipCode = Yup.string().required('Required');
        formValidationSchema.Country = Yup.string().required('Required');
    }

    if (customRegistrationInputs) {
        customRegistrationInputs.forEach(element => {
            formInitialValues[element.Key] = '';
            formValidationSchema[element.Key] = Yup.string().required('Required');
        });
    }

    return (
        <>
            <div className='pt-10 pb-10'>
                {customIntroductionText && (
                    <div className='pb-10 fs-5 text-gray-400 fw-bold' dangerouslySetInnerHTML={{ __html: customIntroductionText }}></div>
                )}
                <h2 className='fw-bolder d-flex align-items-center text-dark'>Registration</h2>
                <div className='fs-5 text-gray-400 fw-bold'>Please fill-in the form below.</div>
            </div>
            <div className='bg-white p-8 border rounded'>
                <Formik
                    initialValues={formInitialValues}
                    validationSchema={Yup.object(formValidationSchema)}
                    onSubmit={(values: CandidateProfileModel, { setSubmitting }: FormikHelpers<CandidateProfileModel>) => {
                        setTimeout(() => {
                            onFormSubmit(values);
                            setSubmitting(false);
                        }, 500);
                    }}
                >
                    {({ setFieldValue,  errors, touched }) => {                        
                            function resetStates(countryCode: string) {
                              setFieldValue('State', '');
                              setStateItems(states.filter((item) => item.Country === countryCode));
                            }
                  
                            function handleCountryChange(e: any) {
                              const countryCode = e.target.value;
                              setFieldValue('Country', countryCode);
                              resetStates(countryCode);
                            }

                        return (
                        <Form>
                            <div className='row mb-5'>
                                <div className='col-md-6 fv-row'>
                                    <TextBox
                                        inputId={'Email'}
                                        label={'Email'}
                                        placeholder=''
                                        required={true}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </div>
                                <div className='col-md-6 fv-row'>
                                    <TextBox
                                        inputId={'Phone'}
                                        label={'Phone Number'}
                                        placeholder=''
                                        required={false}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-md-6 fv-row'>
                                    <TextBox
                                        inputId={'FirstName'}
                                        label={'First name'}
                                        placeholder=''
                                        required={true}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </div>
                                <div className='col-md-6 fv-row'>
                                    <TextBox
                                        inputId={'LastName'}
                                        label={'Last name'}
                                        placeholder=''
                                        required={true}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </div>
                            </div>
                            {!hideAddress && (
                                <>
                                    <div className='d-flex flex-column mb-5 fv-row'>
                                        <TextBox
                                            inputId={'Address1'}
                                            label={'Address Line 1'}
                                            placeholder=''
                                            required={true}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className='d-flex flex-column mb-5 fv-row'>
                                        <TextBox
                                            inputId={'Address2'}
                                            label={'Address Line 2'}
                                            placeholder=''
                                            required={false}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className='row mb-5'>
                                        <div className='col-md-6 fv-row'>
                                            <TextBox
                                                inputId={'City'}
                                                label={'City'}
                                                placeholder=''
                                                required={true}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </div>
                                        <div className='col-md-6 fv-row'>
                                            <label className='required fs-5 fw-bold mb-2'>State</label>
                                            <Field as='select' id='State' name='State' className='form-select form-select-solid border border-gray-300'>
                                                <option value=''>Select a State...</option>
                                                {stateItems.map(({ StateCode, StateName }) => (
                                                    <option value={StateCode}>{StateName}</option>
                                                ))}
                                            </Field>
                                            {errors.State && touched.State ? <div className='text-danger'>{errors.State}</div> : null}
                                        </div>
                                    </div>
                                    <div className='row g-9 mb-5'>
                                        <div className='col-md-6 fv-row'>
                                            <TextBox
                                                inputId={'ZipCode'}
                                                label={'Zip Code'}
                                                placeholder=''
                                                required={true}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </div>
                                        <div className='col-md-6 fv-row'>
                                        <label className='required fs-5 fw-bold mb-2'>Country</label>
                                            <Field as='select' id='Country' name='Country' className='form-select form-select-solid border border-gray-300' onChange={handleCountryChange}>
                                                <option value=''>Select a Country...</option>
                                                <option value='AU'>Australia</option>
                                                <option value='CA'>Canada</option>
                                                <option value='US'>United States of America</option>                                                
                                            </Field>
                                            {errors.Country && touched.Country ? <div className='text-danger'>{errors.Country}</div> : null}
                                        </div>
                                    </div>
                                </>
                            )}
                            {customRegistrationInputs && customRegistrationInputs.map(element => {
                                if (element.Type === 'Text') {
                                    return (
                                        <div key={element.Key} className='d-flex flex-column mb-5 fv-row'>
                                            <TextBox
                                                inputId={element.Key}
                                                label={element.Label}
                                                placeholder=''
                                                required={true}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </div>
                                    );
                                }
                                else if (element.Type === 'DropDownList') {
                                    return (
                                        <div key={element.Key} className='d-flex flex-column mb-5 fv-row'>
                                            <DropDownList
                                                inputId={element.Key}
                                                label={element.Label}
                                                required={true}
                                                options={element.Options}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </div>
                                    );
                                }
                                else if (element.Type === 'RadioButtonList') {
                                    return (
                                        <div key={element.Key} className='d-flex flex-column mb-5 fv-row'>
                                            <RadioButtonList
                                                inputId={element.Key}
                                                label={element.Label}
                                                required={true}
                                                options={element.Options}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </div>
                                    );
                                }
                                if (element.Type === 'Checkbox') {
                                    return (
                                        <div key={element.Key} className='d-flex flex-column mb-5 fv-row'>
                                            <Checkbox
                                                inputId={element.Key}
                                                label={element.Label}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </div>
                                    );
                                }
                            })}

                            {beforeAgreementText && (
                                <div>
                                    <div className="separator separator-dashed my-3"></div>
                                    <div className='fs-5 fv-row' dangerouslySetInnerHTML={{ __html: beforeAgreementText }}></div>
                                    <div className="separator separator-dashed my-3"></div>
                                </div>
                            )}
                            <div className='fv-row mb-5 pt-2'>
                                <div className='d-flex flex-stack'>
                                    <label className='form-check form-switch form-check-custom form-check-solid'>
                                        <Field className='form-check-input border border-gray-300' id='AgreeToTerms' name='AgreeToTerms' type='checkbox' />
                                        <span className='form-check-label fv-row'>
                                            I have read and agree to the <a href="/agreement" target="_blank">JOBehaviors, Inc. User/Applicant Agreement</a>.
                                        </span>
                                    </label>
                                </div>
                                {errors.AgreeToTerms && touched.AgreeToTerms ? (
                                    <div className='text-danger'>{errors.AgreeToTerms}</div>
                                ) : null}
                            </div>
                            <div className='d-flex flex-column pt-10 flex-center'>
                                <button type='submit' className='btn btn-lg btn-primary me-3'>
                                    <span className='indicator-label'>
                                        Register
                                        <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
                                    </span>
                                </button>
                            </div>
                        </Form>
                    )}}
                </Formik>
            </div>
        </>
    );
};

export default Registration;
