import React from 'react';
import ItemOption from '../ItemOption/ItemOption';
import { OptionModel } from '../../../models/OptionModel';

type Props = {
    itemNumber: number;
    question: string;
    options: OptionModel[];
    selectedOptionId: number;
    showRequiredMessage: boolean;
    onOptionSelected: (optionId: number) => void;
};

const CustomQuestion: React.FC<Props> = ({ itemNumber, question, options, selectedOptionId, showRequiredMessage, onOptionSelected }) => {
    const handleItemOptionSelect = (optionId: number) => {
        onOptionSelected(optionId);
    };

    return (
        <div className='d-flex flex-center'>
            <div className='d-flex justify-content-between mx-auto w-xl-900px'>
                <div className='fv-row'>
                    <div className='row' data-kt-buttons='true' data-kt-buttons-target='[data-kt-button]'>
                        <label className='form-label required fs-5'>
                            {itemNumber}. {question}
                        </label>

                        {options.map((option, index) => (
                            <div key={`item-option-${index}`}>
                                <ItemOption
                                    questionId={option.CustomQuestionID}
                                    optionId={option.CustomOptionID}
                                    label={option.OptionText}
                                    isSelected={option.CustomOptionID === selectedOptionId}
                                    onSelect={handleItemOptionSelect}
                                />
                            </div>
                        ))}

                        {showRequiredMessage && (
                            <div className='text-danger mt-2'>You have to select one before you can continue.</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomQuestion;
