import React, { useEffect, useState } from 'react';
import CustomQuestion from '../CustomQuestion/CustomQuestion';
import ProgressBar from '../ProgressBar/ProgressBar';
import { CustomQuestionModel } from '../../../models/CustomQuestionModel';
import { CustomAnswerModel } from '../../../models/CustomAnswerModel';
import SubmitButton from '../SubmitButton/SubmitButton';
import NavigationButtons from '../NavigationButtons/NavigationButtons';

type Props = {
    customQuestions: CustomQuestionModel[];
    startQuestionNumber: number;
    onFormSubmit: (answers: CustomAnswerModel[]) => void;
};

const CustomQuestionnaire: React.FC<Props> = ({ customQuestions, startQuestionNumber, onFormSubmit }) => {
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(startQuestionNumber);
    const [highestSeenQuestionNumber, setHighestSeenQuestionNumber] = useState(startQuestionNumber);
    const [userAnswers, setUserAnswers] = useState([] as CustomAnswerModel[]);
    const [showRequiredMessage, setShowRequiredMessage] = useState(false);

    useEffect(() => {
        if (currentQuestionNumber > highestSeenQuestionNumber) {
            setHighestSeenQuestionNumber(currentQuestionNumber);
        }
    }, [currentQuestionNumber]);

    const handlePreviousButtonClick = () => {
        if (currentQuestionNumber > 1) {
            setShowRequiredMessage(false);
            setCurrentQuestionNumber(currentQuestionNumber - 1);
        }
    };

    const handleNextButtonClick = () => {
        if (currentQuestionNumber < customQuestions.length) {
            const currentAnswer = getCurrentUserAnswer();
            if (!currentAnswer || !currentAnswer.CustomOptionId) {
                setShowRequiredMessage(true);
            } else {
                setShowRequiredMessage(false);
                setCurrentQuestionNumber(currentQuestionNumber + 1);
            }
        }
    };

    const handleSubmitButtonClick = () => {
        if (currentQuestionNumber === customQuestions.length) {
            const currentAnswer = getCurrentUserAnswer();
            if (!currentAnswer || !currentAnswer.CustomOptionId) {
                setShowRequiredMessage(true);
            } else {
                setShowRequiredMessage(false);
                onFormSubmit(userAnswers);
            }
        }
    };

    const handleOptionSelected = (optionId: number) => {
        const currentQuestion = getCurrentQuestion();
        if (currentQuestion) {
            const currentUserAnswers = [...userAnswers];
            currentUserAnswers[currentQuestionNumber - 1] = {
                CustomQuestionId: currentQuestion.CustomQuestionID,
                CustomOptionId: optionId,
            };
            setUserAnswers(currentUserAnswers);
            setShowRequiredMessage(false);
            if (currentQuestionNumber < customQuestions.length) {
                setTimeout(() => {
                    setCurrentQuestionNumber(currentQuestionNumber + 1);
                }, 300);
            }
        }
    };

    const getCurrentQuestion = (): CustomQuestionModel | undefined => {
        return customQuestions[currentQuestionNumber - 1];
    };

    const getCurrentUserAnswer = (): CustomAnswerModel | undefined => {
        return userAnswers[currentQuestionNumber - 1];
    };

    return (
        <>
            <div className='pt-10 pb-10' style={{ maxWidth: '400px' }}>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>Pre-Assessment Questions</h2>
                <div className='fs-5 text-gray-400 fw-bold'>
                    Please answer the questions below before proceeding to the Assessment proper.
                </div>
            </div>
            <div className='bg-white p-8 border rounded'>
                <ProgressBar
                    currentQuestionNumber={currentQuestionNumber}
                    questionCount={customQuestions.length}
                />
                <div className='card bg-light mb-4'>
                    <div className='card-body pt-10 pb-6'>
                        <CustomQuestion
                            itemNumber={currentQuestionNumber}
                            question={getCurrentQuestion()?.CustomQuestionText ?? ''}
                            options={getCurrentQuestion()?.Options ?? []}
                            selectedOptionId={getCurrentUserAnswer()?.CustomOptionId ?? 0}
                            showRequiredMessage={showRequiredMessage}
                            onOptionSelected={handleOptionSelected}
                        />
                        <div className='d-flex justify-content-end'>
                            <NavigationButtons
                                showPreviousButton={currentQuestionNumber > 1}
                                showNextButton={currentQuestionNumber < customQuestions.length && currentQuestionNumber < highestSeenQuestionNumber}
                                onPrevious={handlePreviousButtonClick}
                                onNext={handleNextButtonClick}
                            />
                        </div>
                    </div>
                </div>
                <SubmitButton
                    show={userAnswers.length === customQuestions.length && currentQuestionNumber === customQuestions.length}
                    onSubmit={handleSubmitButtonClick}
                />
            </div>
        </>
    );
};

export default CustomQuestionnaire;
