import { Field, FormikErrors, FormikTouched } from 'formik';
import React from 'react';

type Props = {
    inputId: string;
    label: string;
    placeholder: string;
    required: boolean;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
};

const TextBox: React.FC<Props> = ({ inputId, label, placeholder, required, errors, touched }) => {
    return (
        <>
            <label className={`fs-5 fw-bold mb-2${required ? ' required' : ''}`}>{label}</label>
            <Field
                type='text'
                id={inputId}
                name={inputId}
                className='form-control form-control-solid border border-gray-300'
                placeholder={placeholder}
            />
            {(errors as any)[inputId] && (touched as any)[inputId] 
                ? <div className='text-danger'>{(errors as any)[inputId]}</div> 
                : null}
        </>
    );
};

export default TextBox;
