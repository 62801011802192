import { Field, FormikErrors, FormikTouched } from 'formik';
import React from 'react';

type Props = {
    inputId: string;
    label: string;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
};

const Checkbox: React.FC<Props> = ({ inputId, label, errors, touched }) => {
    return (
        <>
            <div className='d-flex flex-stack'>
                <label className='form-check form-switch form-check-custom form-check-solid'>
                    <Field className='form-check-input border border-gray-300' id={inputId} name={inputId} type='checkbox' />
                    <span className='form-check-label fw-bold'>
                        {label}
                    </span>
                </label>
            </div>
            {(errors as any)[inputId] && (touched as any)[inputId] 
                ? <div className='text-danger'>{(errors as any)[inputId]}</div> 
                : null}
        </>
    );
};

export default Checkbox;
