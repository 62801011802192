import React from 'react';
import ItemOption from '../ItemOption/ItemOption';
import { AnswerModel } from '../../../models/AnswerModel';

type Props = {
    itemNumber: number;
    options: AnswerModel[];
    selectedOptionId: number;
    showRequiredMessage: boolean;
    onOptionSelected: (optionId: number) => void;
};

const PairQuestion: React.FC<Props> = ({ itemNumber, options, selectedOptionId, showRequiredMessage, onOptionSelected }) => {
    const handleItemOptionSelect = (optionId: number) => {
        onOptionSelected(optionId);
    };

    return (
        <div className='d-flex flex-center'>
            <div className='d-flex justify-content-between mx-auto w-xl-900px'>
                <div className='fv-row'>
                    <div className='row' data-kt-buttons='true' data-kt-buttons-target='[data-kt-button]'>
                        <div className='col-sm-1'>
                            <h3 className='pt-6 text-nowrap'>{itemNumber}.</h3>
                        </div>

                        {options.map((option, index) => (
                            <React.Fragment key={`item-option-${index}`}>
                                {index > 0 && <div className='col-sm-1'></div>}
                                <div className='col-sm-11'>
                                    <ItemOption
                                        questionId={itemNumber}
                                        optionId={option.AnswerID}
                                        label={option.AnswerText}
                                        isSelected={option.AnswerID === selectedOptionId}
                                        onSelect={handleItemOptionSelect}
                                    />
                                </div>
                            </React.Fragment>
                        ))}

                        {showRequiredMessage && (
                            <>
                                <div className='col-sm-1'></div>
                                <div className='col-sm-11 text-danger mt-2'>You have to select one before you can continue.</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default PairQuestion;
