import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AssessmentPage from './pages/AssessmentPage/AssessmentPage';

const Routes = () => (
    <BrowserRouter>
        <div>
            <Switch>
                <Route exact path='/:assessmentId/:locAssessmentId?' component={AssessmentPage} />
            </Switch>
        </div>
    </BrowserRouter>
);

export default Routes;
