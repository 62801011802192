import { Field, FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { KeyValueModel } from '../../../models/KeyValueModel';

type Props = {
    inputId: string;
    label: string;
    required: boolean;
    options: KeyValueModel[];
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
};

const DropDownList: React.FC<Props> = ({ inputId, label, required, options, errors, touched }) => {
    return (
        <>
            <label className={`fs-5 fw-bold mb-2${required ? ' required' : ''}`}>{label}</label>
            <Field as='select' id={inputId} name={inputId} className='form-select form-select-solid border border-gray-300'>
                <option value=''>Select one...</option>
                {options && options.map(option => (
                    <option key={option.Key} value={option.Key}>{option.Value}</option>
                ))}
            </Field>
            {(errors as any)[inputId] && (touched as any)[inputId] 
                ? <div className='text-danger'>{(errors as any)[inputId]}</div> 
                : null}
        </>
    );
};

export default DropDownList;
