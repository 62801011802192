import { Field, FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { KeyValueModel } from '../../../models/KeyValueModel';

type Props = {
    inputId: string;
    label: string;
    required: boolean;
    options: KeyValueModel[];
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
};

const RadioButtonList: React.FC<Props> = ({ inputId, label, required, options, errors, touched }) => {
    return (
        <>
            <label className={`fs-5 fw-bold mb-2${required ? ' required' : ''}`}>{label}</label>
            <div className='d-flex mt-2'>
                {options && options.map(option => (
                    <div key={option.Key} className='form-check form-check-custom form-check-solid me-10'>
                        <Field
                            type='radio'
                            id={inputId}
                            name={inputId}
                            value={option.Key}
                            className='form-check-input border border-gray-300'
                        />
                        <label className='form-check-label'>{option.Value}</label>
                    </div>
                ))}
            </div>
            {(errors as any)[inputId] && (touched as any)[inputId] 
                ? <div className='text-danger'>{(errors as any)[inputId]}</div> 
                : null}
        </>
    );
};

export default RadioButtonList;
